<template>
  <div class="Enter-box">
    <p class="Enterprise-p"><i></i><span>企业认证</span></p>
    <div class="Enterprise">
      <div class="Enterprise-title">
        <span></span>
        <p>企业信息</p>
      </div>
      <div class="Enterprise-Newly">
        <div class="Enterprise-Newly-box" v-for="(item,index) in list" :key="index">
          <!-- 查看跳转页面~ -->
          <p @click="Examine(item)">{{ item.ent_name }}</p>
          <span>有效期至 {{ item.valid_term ? item.valid_term : ' —— ——' }}</span>
          <!-- 提交时间 -->
          <!-- <span v-if="item.aut_time == null ">提交时间 {{ item.aut_time? item.aut_time : ' —— —— ' }}</span> -->
          <span v-if="item.operate_time == null "> 认证时间{{ item.aut_time? item.aut_time : ' —— —— ' }}</span>
          <div class="Enterprise-Newly-state">
            <!-- 未认证 -->
            <ul v-if='item.aut_status == 0'>
              <li class="Enterprise-red"><span>认证中</span></li>
            </ul>
            <!-- 已认证 -->
            <ul v-if='item.aut_status == 1'>
              <li class="Enterprise-green"><span>已认证</span></li>
              <!-- <li @click="dissolve(item)"><span>解散</span></li>
              <li class="Enterprise-red" @click="handleToEnterpriseConfirm(item)"><span>续费</span></li> -->
            </ul>
            <!-- 拒绝 -->
            <ul v-if='item.aut_status == 2'>
              <li class="Enterprise-red"><span>未通过</span></li>
              <!-- @click="alter(item)" -->
              <li @click="alter(item)"><span>修改</span></li>
              <li @click="Stockout(item)"><span>删除</span></li>
            </ul>
          </div>
        </div>
        <div class="Enterprise-Newly-box" @click="handleToEnterprise" v-if="list.length < 1">
          <img src="../../../assets/img/xinz.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    query_user_aut_ent_list,
    dismiss_aut_ent,
    delete_un_aut_ent,
    query_user_aut_ent
  } from '@api/pulic'
  export default {
    data() {
      return {
        company_profile: '',
        valid_time: '',
        submission_time: '2013-11-10',
        list: [],
        trueS: true,
        flase: false
      }
    },

    created() {


      this.son()
    },

    methods: {

      son() {
         let self = this

        query_user_aut_ent_list({
          data: {
            user_id: self.$store.state.user_id
          }
        }).then(res => {
          this.list = res.body.data
        })
      },

      // 解散企业
      dissolve(itme) {
        var itmeEnt_id = itme.ent_id
        this.$confirm('解散后不能再登录该企业，是否确认解散？', '餐亿本', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          dismiss_aut_ent({
            data: ({
              ent_id: itmeEnt_id,
              user_id: sessionStorage.getItem('user_id')
              // user_id:self.$store.state.user_id
            })
          }).then(res => {
            console.log(itme.ent_id)
            this.son()
          })
          this.$message({
            type: 'success',
            message: '删除成功!'
          });

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      Stockout(itme) {
        var itmeEnt_id = itme.ent_id
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delete_un_aut_ent({
            data: ({
              ent_id: itmeEnt_id,
              user_id: sessionStorage.getItem('user_id')
            })
          }).then(res => {
            console.log(itme.ent_id)
            this.son()
          })
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      alter(itme) {
        var itmeEnt_idS = itme.ent_id
        query_user_aut_ent({
          data: ({
            ent_id: itmeEnt_idS,
            user_id: sessionStorage.getItem('user_id'),
          })
        }).then(res => {
          this.$router.push({
            name: 'Enterprise',
            params: {
              sss: res.body,
              ent_idX: itme.ent_id
            }
          })

          //  this.$router.push({path:'/selectCate',query:{userid:id}});
        })
      },
      Examine(itme) {
        var itmeEnt_idS = itme.ent_id
        query_user_aut_ent({
          data: ({
            ent_id: itmeEnt_idS,
            user_id: sessionStorage.getItem('user_id'),
          })
        }).then(res => {
          this.$router.push({
            name: 'EnterpriseExamine',
            params: {
              sss: res.body,
              ent_idX: itme.ent_id
            }
          })

          //  this.$router.push({path:'/selectCate',query:{userid:id}});
        })
      },

      // 跳转到企业认证页面
      handleToEnterprise() {
        this.$router.push('/EnterpriseXin')

      },
      // 跳转到续费页面
      handleToEnterpriseConfirm(items) {
        console.log(items);

        var itmeEnt_idS = items.ent_id
        query_user_aut_ent({
          data: ({
            ent_id: itmeEnt_idS,
            user_id: sessionStorage.getItem('user_id'),
          })
        }).then(res => {
          if (res.code == '10101') {
            this.$router.push({
              name: 'EnterpriseConfirm',
              params: {
                order_type:'1',
                item: res.body.data,
                ent_idX: items.ent_id
              }
            })
          }


        })

        // let item = JSON.stringify(items)
        // this.$router.push({name:'EnterpriseConfirm',
        //     params :{
        //       item:item
        //     }
        // })

        // this.$router.push({path:`/EnterpriseConfirm/${item}`})
      }

    }
  }

</script>

<style scoed lang="scss">
  .Enter-box {
    .el-main {

    }

    .Enterprise-p {
      font-size: 18px;
      color: #999999;
      display: flex;
      margin: 26px 0 30px 0;

      span {
        margin: 4px;
      }

      i {
        background: url('../../../assets/img/din.png') no-repeat;
        width: 23px;
        height: 30px;
        display: block;
        margin-right: 11px;
      }
    }

    .Enterprise {
      width: 100%;
      color: #f6f6f3;
      background: #f2f6fc;
      -moz-box-shadow: 0px 0px 10px #e8e8eb;
      -webkit-box-shadow: 0px 0px 10px #e8e8eb;
      box-shadow: 0px 0px 10px #e8e8eb;

      .Enterprise-title {
        display: flex;
        width: 100%;
        height: 80px;
        background: linear-gradient(180deg, #fc4353, #ff5e41);
        border-radius: 15px 15px 0px 0px;
        align-items: center;
        box-sizing: border-box;
        padding-left: 30px;

        span {
          width: 6px;
          height: 30px;
          background: #fff;
          border-radius: 3px;
          margin-right: 11px;
        }

        p {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #fff;
        }
      }

      .Enterprise-Newly {
        display: flex;
        flex-wrap: wrap;
        padding: 25px 0 293px 0;

        .Enterprise-Newly-box {
          width: 299px;
          height: 184px;
          background: #fefefe;
          border-radius: 10px;
          -moz-box-shadow: 0px 0px 10px #fdf3f3;
          -webkit-box-shadow: 0px 0px 10px #fdf3f3;
          box-shadow: 0px 0px 10px #fdf3f3;
          margin: 0 13px 20px 13px;

          img {
            width: 100%;
            height: 100%;
          }

          p {
            font-size: 18px;
            color: #333333;
            text-align: center;
            padding: 31px 0 21px 0;
          }

          span {
            font-size: 16px;
            color: #999999;
            display: block;
            text-align: center;

            &:nth-child(2) {
              margin-bottom: 10px;
            }

            &:hover {
              cursor: pointer;
            }
          }

          .Enterprise-Newly-state {
            ul {
              display: flex;
              justify-content: center;
              margin-top: 20px;

              li {
                width: 60px;
                height: 30px;
                display: block;
                border-radius: 15px;
                color: #666666;
                line-height: 30px;
                border: 1px #666666 solid;
                margin-right: 30px;

                &:last-of-type {
                  margin-right: 0;
                }
              }

              .Enterprise-green {
                background: #3cb939;
                color: #fff;
                border: 1px #3cb939 solid;

                span {
                  width: 100%;
                  height: 100%;
                  border-radius: 15px;
                  color: #fff;
                }
              }

              .Enterprise-red {
                background: #fc4453;
                color: #fff;
                border: 1px #fc4453 solid;

                span {
                  width: 100%;
                  height: 100%;
                  border-radius: 15px;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

</style>
